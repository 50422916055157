import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";

interface PostProps {
  node: {
    id: string;
    frontmatter: {
      id: string;
      title: string;
      author: string;
      date: string;
      excerpt: string;
      path: string;
      postPicture: {
        childImageSharp: {
          fluid: FluidObject;
        };
      };
    };
  };
}

interface PostListProps {
  edges: PostProps[];
}

interface PodcastProps {
  node: {
    id: number;
    name: string;
    date: string;
    url: string;
    featuredImage: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    regularImage: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
  };
}

interface PodcastListProps {
  edges: PodcastProps[];
}

const Article: React.FC<{ edge: PostProps; index: number }> = ({
  edge,
  index,
}) => {
  const { node } = edge;
  const cls = index === 0;
  return (
    <article
      className={`flex ${
        cls ? "flex-col" : "flex-row-reverse"
      } justify-between my-5 w-full`}
      id={node.id}
    >
      <Link to={`/blog${node.frontmatter.path}`}>
        <Img
          fluid={node.frontmatter.postPicture.childImageSharp.fluid}
          className={`${cls ? "w-full" : "w-24 sm:w-64"} h-24 shadow shadow-md`}
        />
      </Link>
      <div className={`block ${cls ? "w-full mt-2" : "w-3/4 mr-2"}`}>
        <Link to={`/blog${node.frontmatter.path}`}>
          <h2 className='text-lg font-extrabold leading-5'>
            {node.frontmatter.title}
          </h2>
        </Link>
        <summary>
          <h4 className='text-sm leading-tight my-1'>
            {node.frontmatter.excerpt}
          </h4>
        </summary>
        <div className='flex flex-row divide-x divide-gray-400'>
          <h5 className='font-extrabold text-sm mr-2'>
            {node.frontmatter.author}
          </h5>
          <h5 className='pl-2 text-sm'>{node.frontmatter.date}</h5>
        </div>
      </div>
    </article>
  );
};

const Podcast: React.FC<{ edge: PodcastProps; index: number }> = ({
  edge,
  index,
}) => {
  const { node } = edge;
  return (
    <div
      className={`${
        index === 0 ? "col-span-2 sm:row-span-2" : "col-span-1 sm:row-span-1"
      } bg-podcast shadow shadow-sm`}
    >
      <a
        href={node.url}
        target='_blank'
        rel='noopener noreferrer'
        className='mx-auto'
        aria-label={node.name}
      >
        {index === 0 ? (
          <div className='block w-full py-5 px-5 mx-auto'>
            <Img
              fluid={node.featuredImage.childImageSharp.fluid}
              className='w-32 mx-auto rounded rounded-full shadow shadow-xl shadow-outline-teal'
            />
            <div className='my-5 block mx-auto text-white antialiased text-center'>
              <h1>{node.name}</h1>
              <h1 className='mt-5 text-2xl antialiased text-center leading-tight'>
                <h2>Talk Stories</h2>
                <h1 className='text-5xl font-extrabold mx-auto leading-tight'>
                  Podcast
                </h1>
                <h2>with Data</h2>
              </h1>
            </div>
          </div>
        ) : (
          <div className='w-full p-5 shadow shadow-sm'>
            <Img
              fluid={node.featuredImage.childImageSharp.fluid}
              className='w-28 h-32 mx-auto rounded rounded-full shadow shadow-xl shadow-outline-teal'
            />
            <h4 className='text-center text-white mx-auto mt-3'>{node.name}</h4>
          </div>
        )}
      </a>
    </div>
  );
};

export default () => {
  const { posts, podcasts } = useStaticQuery<{
    posts: PostListProps;
    podcasts: PodcastListProps;
  }>(graphql`
    query {
      posts: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              author
              date(formatString: "MMMM DD")
              excerpt
              path
              postPicture {
                childImageSharp {
                  fluid(maxWidth: 420, toFormat: WEBP) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      podcasts: allPodcastsJson {
        edges {
          node {
            id
            name
            date(fromNow: true)
            url
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <SEO
        lang='en'
        meta={[]}
        title='Product presentation'
        description='A data-exploration platform'
      />
      <Layout>
        <div className='inline-block font-primary w-full min-h-screen'>
          <section className='my-5 mx-5 lg:mx-auto lg:max-w-4xl'>
            <h4 className='text-4xl font-extrabold'>Blog</h4>
            {!!posts
              ? posts.edges.map((elem, index) => (
                  <Article
                    edge={elem}
                    index={index}
                    key={elem.node.id.substr(0, 8)}
                  />
                ))
              : ""}
          </section>
          <section className='my-5 mx-5 lg:mx-auto lg:max-w-4xl'>
            <h4 className='text-4xl font-extrabold'>Podcasts</h4>
            <div className='grid grid-cols-2 grid-flow-row sm:grid-flow-col sm:grid-rows-2 gap-5'>
              {!!podcasts
                ? podcasts.edges.map((podcast, index) => (
                    <Podcast
                      edge={podcast}
                      index={index}
                      key={podcast.node.id.toString(32).substr(0, 8)}
                    />
                  ))
                : ""}
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};
